import config from '../../../config'
import store from '../../store'
import moment from 'moment'
import jsPDF from 'jspdf'
import QRCode from 'qrcode'
import { PDFDocument, StandardFonts } from 'pdf-lib'
import { NumerosALetras } from 'numero-a-letras'
import { format_money, ArrayBufferToBase64, divideArray, convertDecimals, str, roundNumber, cadenaVacia } from '../utils'

async function get_pdf (nombre) {
  // devuelve los bytes del pdf que esta almacenado en la api
  const pdfBytes = await fetch(`${config.BASE_URL}/pdf?nombre=${nombre}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      'Authorization': store.state.long_token
    },
  }).then(res => res.arrayBuffer())

  return pdfBytes
}

export async function get_file (path, tipo) {
  // devuelve los bytes del pdf que esta almacenado en la api
  const fileBytes = await fetch(`${config.BASE_URL}/file?path=${path}`, {
    method: 'GET',
    headers: {
      'Content-Type': tipo,
      'Authorization': store.state.long_token
    },
  }).then(res => res.arrayBuffer())

  return fileBytes
}

// si es muy largo un nombre lo recorta en la 'longMax' y devuelve 2 partes
function salto_linea (nombre, longMax) {
  if (nombre.length > longMax) {
    let indices = []
    // carga un array con los indices donde hay espacios
    for(let i = 0; i < nombre.length; i++) {
      if (nombre[i] === ' ') indices.push(i)
    }

    /*
    // calcula cuál es el indice que está más a la mitad del array
    const mitad =  indices[Math.trunc(indices.length / 2)]
    */

    // calcula cual es el indice que mas se aproxima a la longitud maxima permitida
    let sub = indices.filter(i => i < longMax)
    const mitad = sub.length == 0 ? indices[0] : sub[sub.length - 1]

    return {
      parte1: nombre.substring(0, mitad),
      parte2: nombre.substring(mitad + 1, nombre.length)
    }
    
  } else {
    return {
      parte1: nombre,
      parte2: ''
    }
  }
}

function split_varchar (varchar, longitud_maxima) {
  if (varchar.length > longitud_maxima) {
    let result = []
    let string = ''
    let cadena = varchar.split(' ')

    for (const palabra of cadena) {
      let aux = string + palabra
      if (aux.length > longitud_maxima) {
        result.push(string)
        string = `${palabra} `
      } else {
        string += `${palabra} `
      }
    }
    if (string.trim() != '') result.push(string)
    return result
  } else {
    return [varchar]
  }
}

async function pdfCobroOnlineTermica(data){
  try{
    // obtengo la primer plantilla
    const existingPdfBytes = await get_pdf('pdfCompElectronicoEpsonExitosoOriginal')
    // obtengo la segunda plantilla
    const existingPdfBytess = await get_pdf('pdfCompElectronicoEpsonExitosoCopia')
    // instancio dos documentos
    const pdfDoc1 = await PDFDocument.load(existingPdfBytes)
    const pdfDoc2 = await PDFDocument.load(existingPdfBytess)
    // obtengo mi documento principal
    const pdfDoc = await PDFDocument.create()
    const [docPage1] = await pdfDoc.copyPages(pdfDoc1, [0])
    const [docPage2] = await pdfDoc.copyPages(pdfDoc2, [0])
    // inserto las paginas en mi documento principal
    pdfDoc.addPage(docPage1)
    pdfDoc.insertPage(1, docPage2)
    // empiezo a escribir
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const pages = pdfDoc.getPages()
    for (let id = 0;id <= 1; id++){
      const actualPage = pages[id]
      // fecha
      let fecha = ''
      let fechaComp= ''
      if (!isNaN(data.tdate)){
        fecha = moment.unix(parseInt(data.tdate)).format("DD/MM/YYYY")
        fechaComp = moment.unix(parseInt(data.tdate))
      }else{
        fecha = moment(data.tdate.toString()).format("DD/MM/YYYY")
        fechaComp = moment(data.tdate.toString())
      }
      actualPage.drawText(fecha.toString(), {
        x: 25,
        y: 430,
        size: 7,
        font: helveticaFont,
      })
      // marca
      actualPage.drawText(data.marca, {
        x: 80,
        y: 430,
        size: 7,
        font: helveticaFont,
      })
      // hora
      actualPage.drawText(moment(fechaComp).format("HH:mm"), {
        x: 145,
        y: 430,
        size: 7,
        font: helveticaFont,
      })
      // empresa
      actualPage.drawText(data.empresa_nombre, {
        x: 55,
        y: 410,
        size: 7,
        font: helveticaFont,
      })
      // domicilio
      actualPage.drawText(data.empresa_domicilio, {
        x: 53,
        y: 402,
        size: 7,
        font: helveticaFont,
      })
      // cuit
      actualPage.drawText(data.empresa_cuit, {
        x: 43,
        y: 390,
        size: 7,
        font: helveticaFont,
      })
      // comercio
      let comercio = '-'
      if (data.comercio_id != null && data.comercio_id != undefined && data.comercio_id.toString().length > 0){
        comercio = data.comercio_id.toString()
      }
      actualPage.drawText(comercio, {
        x: 135,
        y: 390,
        size: 7,
        font: helveticaFont
      })
      // tarjeta
      actualPage.drawText(data.tarjeta_numero, {
        x: 90,
        y: 380,
        size: 7,
        font: helveticaFont
      })
      // titular
      actualPage.drawText(data.cliente_nombre, {
        x: 45,
        y: 370,
        size: 7,
        font: helveticaFont
      })
      // telefono
      let telefono = '-'
      if (data.cliente_telefono != null && data.cliente_telefono != undefined && data.cliente_telefono.toString().length > 0){
        telefono = data.cliente_telefono.toString()
      }
      actualPage.drawText(telefono, {
        x: 53,
        y: 360,
        size: 7,
        font: helveticaFont
      })
      // codigo autorizacion
      actualPage.drawText(data.codigo_autorizacion, {
        x: 72,
        y: 349,
        size: 7,
        font: helveticaFont
      })
      // transaccion
      actualPage.drawText(data.transaccion_id, {
        x: 61,
        y: 338,
        size: 7,
        font: helveticaFont
      })
      // numero
      actualPage.drawText(data.numero, {
        x: 50,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // lote
      let lote = ''
      if (data.lote != null && data.lote != undefined && data.lote.toString().length > 0) lote = data.lote
      actualPage.drawText(lote, {
        x: 95,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // cuotas
      actualPage.drawText(data.cuotas.toString(), {
        x: 137,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // solicitud de adhesion
      if (data.solicitud_adhesion != null && data.solicitud_adhesion != undefined && data.solicitud_adhesion.toString().length > 0){
        actualPage.drawText('Solicitud Adhesion: ' + data.solicitud_adhesion.toString(), {
          x: 26,
          y: 317,
          size: 7,
          font: helveticaFont
        })
      }
      // total
      actualPage.drawText(convertDecimals(data.importe).toString(), {
        x: 73,
        y: 304,
        size: 10,
        font: helveticaFont
      })
      // original
      if (id == 0){
        actualPage.drawText('ORIGINAL COMERCIO', {
          x: 62,
          y: 240,
          size: 8,
          font: helveticaFont
        })
      }
    }
    let pdfBytes = await pdfDoc.save()
    return {
      resultado: 1,
      msj: 'OK',
      pdf: pdfBytes.buffer
    }
  }catch(error){
    return {
      resultado: 0,
      msj: 'Ocurrio un problema al ejecutar el metodo pdfCobroOnlineTermica: ' + error.message,
      pdf: null
    }
  }
}

async function pdfCobroOnlineTermicaAnulacion(data){
  try{
    // obtengo la primer plantilla
    const existingPdfBytes = await get_pdf('pdfCompElectronicoEpsonAnulacionOriginal')
    // obtengo la segunda plantilla
    const existingPdfBytess = await get_pdf('pdfCompElectronicoEpsonAnulacionCopia')
    // instancio dos documentos
    const pdfDoc1 = await PDFDocument.load(existingPdfBytes)
    const pdfDoc2 = await PDFDocument.load(existingPdfBytess)
    // obtengo mi documento principal
    const pdfDoc = await PDFDocument.create()
    const [docPage1] = await pdfDoc.copyPages(pdfDoc1, [0])
    const [docPage2] = await pdfDoc.copyPages(pdfDoc2, [0])
    // inserto las paginas en mi documento principal
    pdfDoc.addPage(docPage1)
    pdfDoc.insertPage(1, docPage2)
    // empiezo a escribir
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const pages = pdfDoc.getPages()
    for (let id = 0;id <= 1; id++){
      const actualPage = pages[id]
      // fecha
      let fecha = ''
      let fechaComp= ''
      if (!isNaN(data.tdate)){
        fecha = moment.unix(parseInt(data.tdate)).format("DD/MM/YYYY")
        fechaComp = moment.unix(parseInt(data.tdate))
      }else{
        fecha = moment(data.tdate.toString()).format("DD/MM/YYYY")
        fechaComp = moment(data.tdate.toString())
      }
      actualPage.drawText(fecha.toString(), {
        x: 25,
        y: 430,
        size: 7,
        font: helveticaFont,
      })
      // marca
      actualPage.drawText(data.marca, {
        x: 80,
        y: 430,
        size: 7,
        font: helveticaFont,
      })
      // hora
      actualPage.drawText(moment(fechaComp).format("HH:mm"), {
        x: 145,
        y: 430,
        size: 7,
        font: helveticaFont,
      })
      // empresa
      actualPage.drawText(data.empresa_nombre, {
        x: 55,
        y: 410,
        size: 7,
        font: helveticaFont,
      })
      // domicilio
      actualPage.drawText(data.empresa_domicilio, {
        x: 53,
        y: 402,
        size: 7,
        font: helveticaFont,
      })
      // cuit
      actualPage.drawText(data.empresa_cuit, {
        x: 43,
        y: 390,
        size: 7,
        font: helveticaFont,
      })
      // comercio
      let comercio = '-'
      if (data.comercio_id != null && data.comercio_id != undefined && data.comercio_id.toString().length > 0){
        comercio = data.comercio_id.toString()
      }
      actualPage.drawText(comercio, {
        x: 135,
        y: 390,
        size: 7,
        font: helveticaFont
      })
      // tarjeta
      actualPage.drawText(data.tarjeta_numero, {
        x: 90,
        y: 380,
        size: 7,
        font: helveticaFont
      })
      // titular
      actualPage.drawText(data.cliente_nombre, {
        x: 45,
        y: 370,
        size: 7,
        font: helveticaFont
      })
      // telefono
      let telefono = '-'
      if (data.cliente_telefono != null && data.cliente_telefono != undefined && data.cliente_telefono.toString().length > 0){
        telefono = data.cliente_telefono.toString()
      }
      actualPage.drawText(telefono, {
        x: 53,
        y: 360,
        size: 7,
        font: helveticaFont
      })
      // codigo autorizacion
      actualPage.drawText(data.codigo_autorizacion, {
        x: 72,
        y: 349,
        size: 7,
        font: helveticaFont
      })
      // transaccion
      actualPage.drawText(data.transaccion_id, {
        x: 61,
        y: 338,
        size: 7,
        font: helveticaFont
      })
      // numero
      actualPage.drawText(data.numero, {
        x: 50,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // lote
      let lote = ''
      if (data.lote != null && data.lote != undefined && data.lote.toString().length > 0) lote = data.lote
      actualPage.drawText(lote, {
        x: 95,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // cuotas
      actualPage.drawText(data.cuotas.toString(), {
        x: 137,
        y: 328,
        size: 7,
        font: helveticaFont
      })
      // solicitud de adhesion
      if (data.solicitud_adhesion != null && data.solicitud_adhesion != undefined && data.solicitud_adhesion.toString().length > 0){
        actualPage.drawText('Solicitud Adhesion: ' + data.solicitud_adhesion.toString(), {
          x: 26,
          y: 317,
          size: 7,
          font: helveticaFont
        })
      }
      // total
      actualPage.drawText(convertDecimals(data.importe).toString(), {
        x: 73,
        y: 304,
        size: 10,
        font: helveticaFont
      })
      // original
      if (id == 0){
        actualPage.drawText('ORIGINAL COMERCIO', {
          x: 62,
          y: 240,
          size: 8,
          font: helveticaFont
        })
      }
    }
    let pdfBytes = await pdfDoc.save()
    return {
      resultado: 1,
      msj: 'OK',
      pdf: pdfBytes.buffer
    }
  }catch(error){
    return {
      resultado: 0,
      msj: 'Ocurrio un problema al ejecutar el metodo pdfCobroOnlineTermicaAnulacion: ' + error.message,
      pdf: null
    }
  }
}

async function pdfCobroOnlineA4(data){
  try{
    // obtengo la plantilla
    const existingPdfBytes = await get_pdf('pdfComprobanteElectronicoA4')
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    // empiezo a escribir
    // fecha
    let fecha = ''
    let fechaComp= ''
    if (!isNaN(data.tdate)){
      fecha = moment.unix(parseInt(data.tdate)).format("DD/MM/YYYY")
      fechaComp = moment.unix(parseInt(data.tdate))
    }else{
      fecha = moment(data.tdate.toString()).format("DD/MM/YYYY")
      fechaComp = moment(data.tdate.toString())
    }
    firstPage.drawText(fecha.toString(), {
      x: 50,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(fecha.toString(), {
      x: 260,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    // hora
    firstPage.drawText(moment(fechaComp).format("HH:mm"), {
      x: 110,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(moment(fechaComp).format("HH:mm"), {
      x: 320,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    // empresa
    firstPage.drawText(data.empresa_nombre, {
      x: 30,
      y: 765,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.empresa_nombre, {
      x: 240,
      y: 765,
      size: 7,
      font: helveticaFont,
    })
    // direccion
    firstPage.drawText(data.empresa_domicilio, {
      x: 55,
      y: 752,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.empresa_domicilio, {
      x: 263,
      y: 752,
      size: 7,
      font: helveticaFont,
    })
    // cuit
    firstPage.drawText(data.empresa_cuit, {
      x: 42,
      y: 736,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.empresa_cuit, {
      x: 250,
      y: 736,
      size: 7,
      font: helveticaFont,
    })
    // comercio
    let comercio = '-'
    if (data.comercio_id != null && data.comercio_id != undefined && data.comercio_id.toString().length > 0){
      comercio = data.comercio_id.toString()
    }
    firstPage.drawText(comercio, {
      x: 54,
      y: 720.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(comercio, {
      x: 262,
      y: 720.5,
      size: 7,
      font: helveticaFont,
    })
    // tarjeta
    firstPage.drawText(data.tarjeta_numero, {
      x: 92,
      y: 705,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.tarjeta_numero, {
      x: 302,
      y: 705,
      size: 7,
      font: helveticaFont,
    })
    // titular
    firstPage.drawText(data.cliente_nombre, {
      x: 45,
      y: 689,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.cliente_nombre, {
      x: 254,
      y: 689,
      size: 7,
      font: helveticaFont,
    })
    // telefono
    let telefono = '-'
    if (data.cliente_telefono != null && data.cliente_telefono != undefined && data.cliente_telefono.toString().length > 0){
      telefono = data.cliente_telefono.toString()
    }
    firstPage.drawText(telefono, {
      x: 52,
      y: 674,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(telefono, {
      x: 260,
      y: 674,
      size: 7,
      font: helveticaFont,
    })
    // codigo autorizacion
    firstPage.drawText(data.codigo_autorizacion, {
      x: 71,
      y: 658,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.codigo_autorizacion, {
      x: 280,
      y: 658,
      size: 7,
      font: helveticaFont,
    })
    // numero
    firstPage.drawText(data.numero, {
      x: 50,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.numero, {
      x: 260,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    // lote
    let lote = ''
    if (data.lote != null && data.lote != undefined && data.lote.toString().length > 0) lote = data.lote
    firstPage.drawText(lote, {
      x: 105,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(lote, {
      x: 314,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    // total
    firstPage.drawText(convertDecimals(data.importe).toString(), {
      x: 60,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(convertDecimals(data.importe).toString(), {
      x: 269,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    // cuotas
    firstPage.drawText(data.cuotas.toString(), {
      x: 113,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.cuotas.toString(), {
      x: 322,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    // transaccion
    firstPage.drawText(data.transaccion_id.toString(), {
      x: 61,
      y: 608.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.transaccion_id.toString(), {
      x: 272,
      y: 608.5,
      size: 7,
      font: helveticaFont,
    })
    // marca
    firstPage.drawText('Marca: ' + data.marca.toString(), {
      x: 24,
      y: 593,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText('Marca: ' + data.marca.toString(), {
      x: 234,
      y: 593,
      size: 7,
      font: helveticaFont,
    })
    // solicitud de adhesion
    if (data.solicitud_adhesion != null && data.solicitud_adhesion != undefined && data.solicitud_adhesion.toString().length > 0){
      firstPage.drawText('Solicitud Adhesion: ' + data.solicitud_adhesion.toString(), {
        x: 24,
        y: 580,
        size: 7,
        font: helveticaFont,
      })
      firstPage.drawText('Solicitud Adhesion: ' + data.solicitud_adhesion.toString(), {
        x: 234,
        y: 580,
        size: 7,
        font: helveticaFont,
      })
    }
    // respuesta
    const pdfBytes = await pdfDoc.save()
    return {
      resultado: 1,
      msj: 'OK',
      pdf: pdfBytes.buffer
    }
  }catch(error){
    return {
      resultado: 0,
      msj: 'Ocurrio un problema al ejecutar el metodo pdfCobroOnlineA4: ' + error.message,
      pdf: null
    }
  }
}

async function pdfCobroOnlineA4Anulacion(data){
  try{
    // obtengo la plantilla
    const existingPdfBytes = await get_pdf('pdfCompElectronicoAnulacionA4')
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    // empiezo a escribir
    // fecha
    let fecha = ''
    let fechaComp= ''
    if (!isNaN(data.tdate)){
      fecha = moment.unix(parseInt(data.tdate)).format("DD/MM/YYYY")
      fechaComp = moment.unix(parseInt(data.tdate))
    }else{
      fecha = moment(data.tdate.toString()).format("DD/MM/YYYY")
      fechaComp = moment(data.tdate.toString())
    }
    firstPage.drawText(fecha.toString(), {
      x: 50,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(fecha.toString(), {
      x: 260,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    // hora
    firstPage.drawText(moment(fechaComp).format("HH:mm"), {
      x: 110,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(moment(fechaComp).format("HH:mm"), {
      x: 320,
      y: 799,
      size: 7,
      font: helveticaFont,
    })
    // empresa
    firstPage.drawText(data.empresa_nombre, {
      x: 30,
      y: 765,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.empresa_nombre, {
      x: 240,
      y: 765,
      size: 7,
      font: helveticaFont,
    })
    // direccion
    firstPage.drawText(data.empresa_domicilio, {
      x: 55,
      y: 752,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.empresa_domicilio, {
      x: 263,
      y: 752,
      size: 7,
      font: helveticaFont,
    })
    // cuit
    firstPage.drawText(data.empresa_cuit, {
      x: 42,
      y: 736,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.empresa_cuit, {
      x: 250,
      y: 736,
      size: 7,
      font: helveticaFont,
    })
    // comercio
    let comercio = '-'
    if (data.comercio_id != null && data.comercio_id != undefined && data.comercio_id.toString().length > 0){
      comercio = data.comercio_id.toString()
    }
    firstPage.drawText(comercio, {
      x: 54,
      y: 720.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(comercio, {
      x: 262,
      y: 720.5,
      size: 7,
      font: helveticaFont,
    })
    // tarjeta
    firstPage.drawText(data.tarjeta_numero, {
      x: 92,
      y: 705,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.tarjeta_numero, {
      x: 302,
      y: 705,
      size: 7,
      font: helveticaFont,
    })
    // titular
    firstPage.drawText(data.cliente_nombre, {
      x: 45,
      y: 689,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.cliente_nombre, {
      x: 254,
      y: 689,
      size: 7,
      font: helveticaFont,
    })
    // telefono
    let telefono = '-'
    if (data.cliente_telefono != null && data.cliente_telefono != undefined && data.cliente_telefono.toString().length > 0){
      telefono = data.cliente_telefono.toString()
    }
    firstPage.drawText(telefono, {
      x: 52,
      y: 674,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(telefono, {
      x: 260,
      y: 674,
      size: 7,
      font: helveticaFont,
    })
    // codigo autorizacion
    firstPage.drawText(data.codigo_autorizacion, {
      x: 71,
      y: 658,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.codigo_autorizacion, {
      x: 280,
      y: 658,
      size: 7,
      font: helveticaFont,
    })
    // numero
    firstPage.drawText(data.numero, {
      x: 50,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.numero, {
      x: 260,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    // lote
    let lote = ''
    if (data.lote != null && data.lote != undefined && data.lote.toString().length > 0) lote = data.lote
    firstPage.drawText(lote, {
      x: 105,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(lote, {
      x: 314,
      y: 641.5,
      size: 7,
      font: helveticaFont,
    })
    // total
    firstPage.drawText(convertDecimals(data.importe).toString(), {
      x: 60,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(convertDecimals(data.importe).toString(), {
      x: 269,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    // cuotas
    firstPage.drawText(data.cuotas.toString(), {
      x: 113,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.cuotas.toString(), {
      x: 322,
      y: 625.5,
      size: 7,
      font: helveticaFont,
    })
    // transaccion
    firstPage.drawText(data.transaccion_id.toString(), {
      x: 61,
      y: 608.5,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText(data.transaccion_id.toString(), {
      x: 272,
      y: 608.5,
      size: 7,
      font: helveticaFont,
    })
    // marca
    firstPage.drawText('Marca: ' + data.marca.toString(), {
      x: 24,
      y: 593,
      size: 7,
      font: helveticaFont,
    })
    firstPage.drawText('Marca: ' + data.marca.toString(), {
      x: 234,
      y: 593,
      size: 7,
      font: helveticaFont,
    })
    // solicitud de adhesion
    if (data.solicitud_adhesion != null && data.solicitud_adhesion != undefined && data.solicitud_adhesion.toString().length > 0){
      firstPage.drawText('Solicitud Adhesion: ' + data.solicitud_adhesion.toString(), {
        x: 24,
        y: 580,
        size: 7,
        font: helveticaFont,
      })
      firstPage.drawText('Solicitud Adhesion: ' + data.solicitud_adhesion.toString(), {
        x: 234,
        y: 580,
        size: 7,
        font: helveticaFont,
      })
    }
    // respuesta
    const pdfBytes = await pdfDoc.save()
    return {
      resultado: 1,
      msj: 'OK',
      pdf: pdfBytes.buffer
    }
  }catch(error){
    return {
      resultado: 0,
      msj: 'Ocurrio un problema al ejecutar el metodo pdfCobroOnlineA4Anulacion: ' + error.message,
      pdf: null
    }
  }
}

async function pdfCobroOnline(data){
  try{
    // debo obtener la plantilla de la termica o de A4?
    let pdfPeticion = {}
    if (data.impresora_termica == 1){
      // es anulacion o cobro exitoso?
      if (data.estado == 2 || data.estado == 8 || data.estado == 9){
        // cobro exitoso
        pdfPeticion = await pdfCobroOnlineTermica(data)
      }else{
        // anulacion
        pdfPeticion = await pdfCobroOnlineTermicaAnulacion(data)
      }
    }else{
      // es anulacion o cobro exitoso?
      if (data.estado == 2 || data.estado == 8 || data.estado == 9){
        // cobro exitoso
        pdfPeticion = await pdfCobroOnlineA4(data)
      }else{
        // anulacion
        pdfPeticion = await pdfCobroOnlineA4Anulacion(data)
      }
    }
    return pdfPeticion
  }catch(error){
    return {
      resultado: 0,
      msj: 'Ocurrio un problema al ejecutar el metodo pdfCobroOnline: ' + error.message,
      pdf: null
    }
  }
}

async function operacionesCajaTermica (data) {
  const ancho = 80
  let altura = 0

  if (data.impresora_termica == 1) {
    altura = 800
  } else {
    data.detalle.forEach(() => { altura += 10 })
    data.resumen.forEach(() => { altura += 3.5 })
    altura += 100
  }

  // instancio el pdf de tamaño super extra large
  let doc = new jsPDF({ format: [ancho, altura] })

  // seteo la feunte y cantidad de paginas
  doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'normal')
  doc.setFont('Poppins', 'normal')
  doc.setFontSize(7)
  doc.setLineWidth(0.1)
  /*
  // LINEA ROJA DELIMITANTE DE MARGENES IMP TERMICA
  doc.setDrawColor('#FF5252')
  doc.line(3, 0, 3, 600)
  doc.line(72, 0, 72, 600)
  doc.setDrawColor('#000000')
  */

  const encabezado = { ...data.encabezado }

  // HOJA DETALLE DE OPERACIONES DE CAJA
  doc.text('Caja : ' + encabezado.caja_numero, 3, 6)
  doc.text(encabezado.sucursal, 35, 10, null, null, 'center')
  doc.text('Fecha : ' + moment(encabezado.fecha).format('DD/MM/YYYY'), 48, 6)
  doc.text('Usuario : ' + encabezado.usuario, 72, 3, null, null, 'right')
  doc.text(encabezado.local, 35, 13, null, null, 'center')

  doc.text('Detalle Efectivo', 35, 18.5, null, null, 'center')
  doc.text('Fecha', 3, 23.5)
  doc.text('Reg. Int.', 14, 23.5)
  doc.text('Tipo Comp.', 25, 23.5)
  doc.text('Descripcion', 41, 23.5)
  doc.text('Importe', 60, 23.5)

  let alto = 0
  for (const detalle of data.detalle) {
    doc.line(3, 24.5 + alto, 72, 24.5 + alto)
    
    doc.setFontSize(6)
    doc.text(moment(detalle.fecha).format('DD/MM/YY'), 3, 27 + alto)
    doc.text(str(detalle.cobranza, ''), 14, 27 + alto)

    // tipo comprobante
    doc.setFontSize(5.5)
    const comprobante = salto_linea(detalle.comprobante, 13)
    doc.text(comprobante.parte1, 25, 27 + alto)
    doc.text(comprobante.parte2, 25, 29.5 + alto)

    // descripcion del tipo de comprobante
    doc.setFontSize(5)
    const descripcion = salto_linea(detalle.descripcion, 15)
    doc.text(descripcion.parte1, 42, 27 + alto)
    const descripcion2 = descripcion.parte2.length > 15 ? salto_linea(descripcion.parte2, 5) : descripcion.parte2
    doc.text(descripcion.parte2.length > 15 ? descripcion2.parte1 : descripcion2, 42, 29 + alto)
    doc.text(descripcion.parte2.length > 15 ? descripcion2.parte2 : '', 42, 31 + alto)

    doc.setFontSize(6)
    doc.text(format_money(detalle.importe), 72, 27 + alto, null, null, 'right')

    alto += 10
  }
  doc.line(3, 29 + alto, 72, 29 + alto)

  doc.setFontSize(7)
  doc.text('Cupones TC : ', 3, 32 + alto)
  doc.text(str(data.total_cupones, ''), 22, 32 + alto)
  doc.text('TOTAL : ', 41, 32 + alto)
  doc.text(format_money(data.total_detalle), 72, 32 + alto, null, null, 'right')

  alto -= 10
  // resumen de pagos
  doc.text('Resumen Caja', 35, 57 + alto, null, null, 'center')
  doc.text('Tipo Comp.', 3, 61.5 + alto)
  doc.text('Efectivo', 60, 61.5 + alto)

  for (const det_mov of data.resumen) {
    doc.line(3, 62.5 + alto, 72, 62.5 + alto)
    doc.text(str(det_mov.comprobante, ''), 3, 65 + alto)
    doc.text(format_money(det_mov.efectivo), 72, 65 + alto, null, null, 'right')
    
    alto += 3.5
  }
  doc.line(3, 65 + alto, 72, 65 + alto)

  doc.text('TOTAL : ', 41, 68.5 + alto)
  doc.text(format_money(data.total_resumen), 72, 68.5 + alto, null, null, 'right')
  
  alto += 5
  doc.text('Firma Responsable :', 5, 80 + alto)
  doc.line(32, 80 + alto, 66, 80 + alto)
  doc.text('Aclaracion :', 15, 88 + alto)
  doc.line(32, 88 + alto, 66, 88 + alto)
  doc.text('Fecha :', 21, 94 + alto)
  doc.line(32, 94 + alto, 66, 94 + alto)

  doc.text('.', 3, 97 + alto)

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')
  
}

async function operacionesCajaA4 (data) {
  // defino las medidas de la hoja
  const width = 210  // ancho de 21 cm
  const height = 297 // alto de 29,7 cm
  const margins = 15  // margenes de 1,5 cm

  const limite_inferior = height - margins
  const gris_header = '#e1e1e1' //dcdcdc

  const encabezado = { ...data.encabezado }

  // creo la primer hoja del pdf (por defecto genera una a4)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  // seteo la feunte
  doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'normal')

  /**
   *  Nota: cada row de las tablas que tengan 1 de padding, miden exactamente 5.25 de alto
   *        con este valor se calcula el alto total de la tabla en base a la longitud + 1 del array
   *        se le suma 1 por los encabezados
   */
  const alto_row = 5.25

  // funcion para setear el encabezado
  function setEncabezado () {
    // recuadro rectangular
    doc.setLineWidth(0.1)
    doc.line(margins, margins, width - margins, margins)
    doc.line(margins, margins + 20, width - margins, margins + 20)
    doc.line(margins, margins, margins, margins + 20)
    doc.line(width - margins, margins, width - margins, margins + 20)
    // info
    doc.setFontSize(10)
    doc.setFont('Poppins', 'normal')
    doc.text('Caja Nº : ' + encabezado.caja_numero, margins + 3, margins + 15)
    doc.text(encabezado.local, width / 2, margins + 15, null, null, 'center')
    doc.text('Fecha : ' + moment(encabezado.fecha).format('DD/MM/YYYY'), width - margins - 3, margins + 15, null, null, 'right')
    doc.text('Usuario : ' + encabezado.usuario, width - margins - 3, margins + 7, null, null, 'right')
  }
  setEncabezado()

  ////**  HEADERS PARA LAS TABLAS  *////
  const header_detalle = [
    { id: 'fecha', name: 'fecha', prompt: 'Fecha', width: 25, align: 'left' },
    { id: 'cobranza', name: 'cobranza', prompt: 'Reg. Int.', width: 20, align: 'left' },
    { id: 'comprobante', name: 'comprobante', prompt: 'Tipo Comp.', width: 70, align: 'left' },
    { id: 'descripcion', name: 'descripcion', prompt: 'Descripcion', width: 90, align: 'left' },
    { id: 'importe', name: 'importe', prompt: 'Importe', width: 35, align: 'right' }
  ]
  const header_resumen = [
    { id: 'comprobante', name: 'comprobante', prompt: 'Tipo Comp.', width: 205, align: 'left' },
    { id: 'efectivo', name: 'efectivo', prompt: 'Efectivo', width: 35, align: 'right' }
  ]

  let y_final_elemento = 0
  function funcionMaestra (posicion_inicial_tabla, array, headers) {
    // inicializacion de variables
    y_final_elemento = 0
    let y_inicial_tabla = posicion_inicial_tabla
    let total_rowsXpagina = parseInt((limite_inferior - y_inicial_tabla) / alto_row)
    let subarray = array

    // si el total de rows x pagina es negativo o 0 significa que la tabla no entra en lo que queda de espacio en la hoja
    if (total_rowsXpagina <= 0) {
      total_rowsXpagina = 0
      // si la longitud del array es 0 entonces debo isnertar la cabecera de la tabla ya que en el siguiente "if (y_final_elemento === 0)" no insertaria nada
      if (array.length == 0) {
        doc.addPage()
        setEncabezado()
        // INSERTO LA TABLA
        doc.table(margins, margins + 25, subarray, headers, { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header })
        y_final_elemento = alto_row + margins + 25
      }
    }
    // si el total de rows es mayor a 0 significa que la tabla entera o parte de ella entra en el espacio que queda
    else {
      // VERIFICO SI EL TOTAL DE ROWS X PAGINA ES MENOR QUE LA LONGITUD TOAL DEL ARRAY + 1 (por el encabezado)
      if (total_rowsXpagina < array.length + 1) {
        /**
         *  Si el total de rows x pagina ex menor a la longitud + 1 significa que la tabla no entra en lo que queda
         *  de espacio en la pagina, por lo tanto debo recortar el array
         */
        subarray = array.slice(0, total_rowsXpagina - 1)
      }
      // caso contrario indico el alto final de la tabla
      else {
        y_final_elemento = ((array.length + 1) * alto_row) + y_inicial_tabla
      }

      // INSERTO LA TABLA
      doc.table(margins, y_inicial_tabla, subarray, headers, { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header })
    }

    // SI EL ALTO FINAL DE LA TABLA ES 0 SIGNIFICA QUE LA TABLA NO ENTRÓ (parcial o totalmente) EN LO QUE QUEDABA DE PAGINA Y SE DEBE HACER UN SALTO
    if (y_final_elemento === 0) {
      
      // obtengo el resto de elementos del array que no se insertaron
      subarray = array.slice(total_rowsXpagina, array.length)
      // calculo nuevamente la cantidad de filas que puedo inserar por hoja
      total_rowsXpagina = parseInt((limite_inferior - margins + 25) / alto_row)
      // separo el subarray en grupos que contengan la longitud maxima que permite insertar la tabla en 1 hoja
      const subarray_stack = divideArray(subarray, total_rowsXpagina - 1)

      // recorro los grupos de arrays para insertar las tablas en las paginas
      for (let index = 0; index < subarray_stack.length; index++) {
        const stack = subarray_stack[index]
        doc.addPage()
        // isnerta el encabezado
        setEncabezado()
        // inserta la tabla
        doc.table(margins, margins + 25, stack, headers, { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header })
        // Si se esta insertando la ultima tabla hay que obtener el alto final de la misma para insertar el siguiente elemento a la misma altura
        if (index == subarray_stack.length - 1) {
          y_final_elemento = ((stack.length + 1) * alto_row) + margins + 25
        }
      }
    }
  }

  //**  TABLA DETALLE EFECTIVO  *//
  doc.setFontSize(12)
  doc.text('Detalle Efectivo', width / 2, margins + 30, null, null, 'center')
  funcionMaestra(
    margins + 35,
    data.detalle.map(detalle => {
      detalle.fecha = moment(detalle.fecha).format('DD/MM/YYYY')
      detalle.importe = format_money(detalle.importe)
      return detalle
    }),
    header_detalle
  )
  // VERIFICAMOS QUE EL ALTO DEL SIGUIENTE ELEMENTO ENTRE EN LO QUE QUEDA DE PAGINA sino hacemos un salto de pagina
  function verificarSaltoPagina (altura) {
    if (y_final_elemento + altura > limite_inferior) {
      doc.addPage()
      setEncabezado()
      y_final_elemento = margins + 25
    }
  }
  verificarSaltoPagina(10)
  // inserto el total del detalle efectivo
  doc.setFontSize(11)
  doc.setFont('Poppins', 'normal')
  doc.text('TOTAL : ', 120, y_final_elemento + 7)
  doc.text(format_money(data.total_detalle), width - margins - 1.5, y_final_elemento + 7, null, null, 'right')

  //**  TABLA RESUMEN CAJA  *//
  verificarSaltoPagina(20)
  doc.setFontSize(12)
  doc.text('Resumen Caja', width / 2, y_final_elemento + 20, null, null, 'center')
  funcionMaestra(
    y_final_elemento + 25,
    data.resumen.map(res => {
      res.efectivo = format_money(res.efectivo)
      return res
    }),
    header_resumen
  )
  // inserto el total del resumen
  verificarSaltoPagina(10)
  doc.setFontSize(11)
  doc.setFont('Poppins', 'normal')
  doc.text('TOTAL : ', 120, y_final_elemento + 7)
  doc.text(format_money(data.total_resumen), width - margins - 1.5, y_final_elemento + 7, null, null, 'right')

  //**  FIRMAS  *//
  verificarSaltoPagina(45)
  doc.setFontSize(10)
  doc.text('Firma Responsable :', margins, y_final_elemento + 25)
  doc.text('Aclaracion :', margins, y_final_elemento + 35)
  doc.text('Fecha :', margins, y_final_elemento + 45)

  // agregamos los numeros de pagina al final de cada pagina
  for (let index = 1; index <= doc.getNumberOfPages(); index++) {
    doc.setPage(index)
    doc.text(`Hoja ${index} de ${doc.getNumberOfPages()}`, margins + 3, margins + 7)
  }

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')

}

async function gastosCajaA4 (data) {
  try{
    // defino las medidas de la hoja
    const width = 297  // alto de 29,7 cm
    const height = 210 // ancho de 21 cm
    const margins = 5  // margenes de 1,5 cm

    const limite_inferior = height - margins
    const gris_header = '#e1e1e1' //dcdcdc

    const encabezado = { ...data.encabezado }
    let detalles = data.detalles
    if (detalles.length > 0){
      for (let id in detalles){
        detalles[id].fecha = moment(detalles[id].fecha).format("DD/MM/YYYY")
        detalles[id].fecha_log = moment(detalles[id].fecha_log).format("DD/MM/YYYY")
        detalles[id].importe = roundNumber(detalles[id].importe, 2).toString()
      }
    }
    let resumen = data.resumen.length > 0 ? data.resumen : null
    if (resumen != null && resumen.length > 0){
      resumen[0].comprobante = cadenaVacia(resumen[0].comprobante) ? ' ' : resumen[0].comprobante.toString()
      resumen[0].forma_pago = cadenaVacia(resumen[0].forma_pago) ? ' ' : resumen[0].forma_pago.toString()
      resumen[0].subtotal = cadenaVacia(resumen[0].subtotal) ? ' ' : roundNumber(resumen[0].subtotal, 2).toString()
      resumen[0].egreso = cadenaVacia(resumen[0].egreso) ? ' ' : roundNumber(resumen[0].egreso, 2).toString()
      resumen[0].ingreso = cadenaVacia(resumen[0].ingreso) ? ' ' : roundNumber(resumen[0].ingreso, 2).toString()
    }

    // creo la primer hoja del pdf (por defecto genera una a4)
    let doc = new jsPDF({ putOnlyUsedFonts: false, orientation: "landscape" })

    // seteo la feunte
    doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'normal')
    doc.addFont(require('./fuentes/Poppins-Bold.ttf'), 'Poppins-Bold', 'normal')

    const alto_row = 5.25
    // let nro_hoja = 1
    // let cant_hojas = 0

    function setEncabezado () {
      // recuadro rectangular
      doc.setLineWidth(0.1)
      doc.line(margins, margins, width - margins, margins)
      doc.line(margins, margins + 25, width - margins, margins + 25)
      doc.line(margins, margins, margins, margins + 25)
      doc.line(width - margins, margins, width - margins, margins + 25)
      // info
      doc.setFontSize(13)
      doc.setFont('Poppins', 'normal')
      //doc.text(`Hoja ${nro_hoja} de ${cant_hojas}`, margins + 3, margins + 7)
      doc.text(`Caja Nº : ${encabezado[0].numero}`, margins + 120, margins + 7)
      doc.text(`Fecha: ${moment(encabezado[0].fecha).format("DD/MM/YYYY")}`, margins + 240, margins + 7)
      doc.text(`Local: ${encabezado[0].nombre}`, margins + 3, margins + 14)
      doc.text(`Descripción: ${encabezado[0].descripcion}`, margins + 120, margins + 14)
      doc.text(`Turno: ${encabezado[0].turno}`, margins + 265, margins + 14)
      doc.text(`Usuario: ${encabezado[0].usuario_log}`, margins + 3, margins + 22)
      doc.text(`Estado: ${encabezado[0].caja_estado}`, margins + 120, margins + 22)
    }

    let y_final_elemento = 0
    let ultima_tabla_insertada = []
    function funcionMaestra (posicion_inicial_tabla, array, headers) {
      // inicializacion de variables
      y_final_elemento = 0
      let y_inicial_tabla = posicion_inicial_tabla
      let total_rowsXpagina = 24//parseInt((limite_inferior - y_inicial_tabla) / alto_row)
      let subarray = array
      // si el total de rows x pagina es negativo o 0 significa que la tabla no entra en lo que queda de espacio en la hoja
      if (total_rowsXpagina <= 0) {
        total_rowsXpagina = 0
        // si la longitud del array es 0 entonces debo isnertar la cabecera de la tabla ya que en el siguiente "if (y_final_elemento === 0)" no insertaria nada
        if (array.length == 0) {
          doc.addPage()
          setEncabezado()
          // INSERTO LA TABLA
          ultima_tabla_insertada = subarray
          doc.table(margins, margins + 35, subarray, headers, { autoSize: false, fontSize: 10, padding: 1, headerBackgroundColor: gris_header })
          y_final_elemento = alto_row + margins + 35
        }
      }
      // si el total de rows es mayor a 0 significa que la tabla entera o parte de ella entra en el espacio que queda
      else {
        // VERIFICO SI EL TOTAL DE ROWS X PAGINA ES MENOR QUE LA LONGITUD TOAL DEL ARRAY + 1 (por el encabezado)
        if (total_rowsXpagina < array.length + 1) {
          /**
           *  Si el total de rows x pagina ex menor a la longitud + 1 significa que la tabla no entra en lo que queda
           *  de espacio en la pagina, por lo tanto debo recortar el array
           */
          subarray = array.slice(0, total_rowsXpagina)
        }
        // caso contrario indico el alto final de la tabla
        else {
          y_final_elemento = ((array.length + 1) * alto_row) + y_inicial_tabla
        }
        ultima_tabla_insertada = subarray
        // INSERTO LA TABLA
        doc.table(margins, y_inicial_tabla, subarray, headers, { autoSize: false, fontSize: 10, padding: 1, headerBackgroundColor: gris_header })
      }
      // SI EL ALTO FINAL DE LA TABLA ES 0 SIGNIFICA QUE LA TABLA NO ENTRÓ (parcial o totalmente) EN LO QUE QUEDABA DE PAGINA Y SE DEBE HACER UN SALTO
      if (y_final_elemento === 0) {
        // obtengo el resto de elementos del array que no se insertaron
        subarray = array.slice(total_rowsXpagina, array.length)
        // calculo nuevamente la cantidad de filas que puedo inserar por hoja
        total_rowsXpagina = parseInt((limite_inferior - margins + 25) / alto_row)
        // separo el subarray en grupos que contengan la longitud maxima que permite insertar la tabla en 1 hoja
        const subarray_stack = divideArray(subarray, total_rowsXpagina - 1)
        // recorro los grupos de arrays para insertar las tablas en las paginas
        for (let index = 0; index < subarray_stack.length; index++) {
          const stack = subarray_stack[index]
          ultima_tabla_insertada = stack
          doc.addPage()
          // isnerta el encabezado
          setEncabezado()
          // inserta la tabla
          doc.table(margins, margins + 30, stack, headers, { autoSize: false, fontSize: 10, padding: 1, headerBackgroundColor: gris_header })
          // Si se esta insertando la ultima tabla hay que obtener el alto final de la misma para insertar el siguiente elemento a la misma altura
          if (index == subarray_stack.length - 1) {
            y_final_elemento = ((stack.length + 1) * alto_row) + margins + 35
          }
        }
      }
    }

    setEncabezado()
    // Titulo del Reporte
    doc.setFontSize(16)
    doc.setFont('Poppins-Bold', 'normal')
    doc.text('Reporte Caja - Gastos', margins + 110, margins + 32)

    const header_detalle = [
      { id: 'fecha', name: 'fecha', prompt: 'Fecha', width: 25, align: 'left', style: 'Poppins' },
      { id: 'numero', name: 'numero', prompt: 'Numero', width: 30, align: 'center' },
      { id: 'desc_comp', name: 'desc_comp', prompt: 'Comprobante', width: 100, align: 'left' },
      { id: 'desc_tip', name: 'desc_tip', prompt: 'Descripcion', width: 125, align: 'left' },
      { id: 'usuario_log', name: 'usuario_log', prompt: 'Usuario', width: 45, align: 'center' },
      { id: 'fecha_log', name: 'fecha_log', prompt: 'Fecha Log', width: 25, align: 'center' },
      { id: 'importe', name: 'importe', prompt: 'Importe', width: 30, align: 'right' }
    ]
    const header_resumen = [
      { id: 'forma_pago1', name: 'forma_pago1', prompt: 'Forma de Pago', width: 63, align: 'left', style: 'Poppins' },
      { id: 'comprobante', name: 'comprobante', prompt: 'Comprobante', width: 63, align: 'left', style: 'Poppins' },
      { id: 'descripcion', name: 'descripcion', prompt: 'Descripción', width: 63, align: 'left', style: 'Poppins' },
      { id: 'ingreso', name: 'ingreso', prompt: 'Ingreso', width: 63, align: 'right', style: 'Poppins' },
      { id: 'egreso', name: 'egreso', prompt: 'Egreso', width: 63, align: 'right', style: 'Poppins' },
      { id: 'subtotal', name: 'subtotal', prompt: 'SubTotal', width: 65, align: 'right', style: 'Poppins' }
    ]
    // empezamos a escribir los detalles
    doc.setFontSize(13)
    doc.setFont('Poppins', 'normal')
    funcionMaestra(
      margins + 35,
      detalles,
      header_detalle
    )

    // VERIFICAMOS QUE EL ALTO DEL SIGUIENTE ELEMENTO ENTRE EN LO QUE QUEDA DE PAGINA sino hacemos un salto de pagina
    function verificarSaltoPagina (altura) {
      if (y_final_elemento + altura > limite_inferior) {
        doc.addPage()
        setEncabezado()
        y_final_elemento = margins + 35
      }else y_final_elemento = y_final_elemento + 10
    }
    // calculo la posicion actual de mi y
    if (doc.getNumberOfPages() > 1) y_final_elemento = (ultima_tabla_insertada.length * alto_row) + 50
    else y_final_elemento = (ultima_tabla_insertada.length * alto_row) + 65
    verificarSaltoPagina(20)
    // Resumen de Caja
    doc.setFontSize(16)
    doc.setFont('Poppins-Bold', 'normal')
    doc.text('Resumen de Caja', margins + 110, y_final_elemento)
    doc.table(margins, y_final_elemento + 5, resumen, header_resumen, { autoSize: false, fontSize: 10, padding: 1, headerBackgroundColor: gris_header })

    // calculo la posicion de y
    y_final_elemento = ((resumen.length + 3) * alto_row) + y_final_elemento

    verificarSaltoPagina(30)

    // Firmas
    doc.setFontSize(13)
    doc.setFont('Poppins-Bold', 'normal')
    doc.text('Firma Responsable: ', margins, y_final_elemento)
    doc.text('Aclaración: ', margins, y_final_elemento + 10)
    doc.text('Fecha: ', margins, y_final_elemento + 20)

    // obtengo el Nro de Paginas y numero cada página
    doc.setFont('Poppins', 'normal')
    let total_paginas = doc.getNumberOfPages()
    for (let id = 1; id <= total_paginas; id++){
      doc.setPage(id)
      doc.text(`Hoja ${id} de ${total_paginas}`, margins + 3, margins + 7)
    }
    // devuelvo los bytes del pdf generado
    return doc.output('arraybuffer')
  }catch(error){
    console.log('Ocurrió un problema al generar el reporte de caja: ' + error.message)
    return null
  }
}

async function operacionesAceptacionA4 (data) {
  // defino las medidas de la hoja
  const width = 210  // ancho de 21 cm
  const height = 297 // alto de 29,7 cm
  const margin_y = 15  // margenes de 1,5 cm
  const margin_x = 10  // margenes de 1 cm

  const limite_inferior = height - margin_y
  const gris_header = '#e1e1e1' //dcdcdc

  const encabezado = { ...data.encabezado }

  // creo la primer hoja del pdf (por defecto genera una a4)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  // seteo la feunte
  doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'normal')

  /**
   *  Nota: cada row de las tablas que tengan 1 de padding, miden exactamente 5.25 de alto
   *        con este valor se calcula el alto total de la tabla en base a la longitud + 1 del array
   *        se le suma 1 por los encabezados
   */
  const alto_row = 5.25

  // funcion para setear el encabezado
  function setEncabezado () {
    // recuadro rectangular
    doc.setLineWidth(0.1)
    doc.line(margin_x, margin_y, width - margin_x, margin_y)
    doc.line(margin_x, margin_y + 20, width - margin_x, margin_y + 20)
    doc.line(margin_x, margin_y, margin_x, margin_y + 20)
    doc.line(width - margin_x, margin_y, width - margin_x, margin_y + 20)
    // info
    doc.setFontSize(8)
    doc.setFont('Poppins', 'normal')
    doc.text('Recibo Aceptación Nº : ' + encabezado.aceptacion, margin_x + 3, margin_y + 11)
    doc.text(encabezado.descripcion, width / 2, margin_y + 11, null, null, 'center')
    doc.text('Caja Nº : ' + encabezado.caja_numero, width - margin_x - 3, margin_y + 11, null, null, 'right')
    doc.text('Fecha Apertura : ' + encabezado.fecha_apertura, margin_x + 3, margin_y + 17)
    doc.text('Estado : ' + encabezado.estado, width / 2, margin_y + 17, null, null, 'center')
    doc.text('Turno : ' + encabezado.turno, width - margin_x - 3, margin_y + 17, null, null, 'right')
  }
  setEncabezado()

  let y_final_elemento = 0
  function funcionMaestra (posicion_inicial_tabla, array, headers) {
    // inicializacion de variables
    y_final_elemento = 0
    let y_inicial_tabla = posicion_inicial_tabla
    let total_rowsXpagina = parseInt((limite_inferior - y_inicial_tabla) / alto_row)
    let subarray = array

    // si el total de rows x pagina es negativo o 0 significa que la tabla no entra en lo que queda de espacio en la hoja
    if (total_rowsXpagina <= 0) {
      total_rowsXpagina = 0
      // si la longitud del array es 0 entonces debo isnertar la cabecera de la tabla ya que en el siguiente "if (y_final_elemento === 0)" no insertaria nada
      if (array.length == 0) {
        doc.addPage()
        setEncabezado()
        // INSERTO LA TABLA
        doc.table(margin_x, margin_y + 25, subarray, headers, { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header })
        y_final_elemento = alto_row + margin_y + 25
      }
    }
    // si el total de rows es mayor a 0 significa que la tabla entera o parte de ella entra en el espacio que queda
    else {
      // VERIFICO SI EL TOTAL DE ROWS X PAGINA ES MENOR QUE LA LONGITUD TOAL DEL ARRAY + 1 (por el encabezado)
      if (total_rowsXpagina < array.length + 1) {
        /**
         *  Si el total de rows x pagina ex menor a la longitud + 1 significa que la tabla no entra en lo que queda
         *  de espacio en la pagina, por lo tanto debo recortar el array
         */
        subarray = array.slice(0, total_rowsXpagina - 1)
      }
      // caso contrario indico el alto final de la tabla
      else {
        y_final_elemento = ((array.length + 1) * alto_row) + y_inicial_tabla
      }

      // INSERTO LA TABLA
      doc.table(margin_x, y_inicial_tabla, subarray, headers, { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header })
    }

    // SI EL ALTO FINAL DE LA TABLA ES 0 SIGNIFICA QUE LA TABLA NO ENTRÓ (parcial o totalmente) EN LO QUE QUEDABA DE PAGINA Y SE DEBE HACER UN SALTO
    if (y_final_elemento === 0) {
      
      // obtengo el resto de elementos del array que no se insertaron
      subarray = array.slice(total_rowsXpagina, array.length)
      // calculo nuevamente la cantidad de filas que puedo inserar por hoja
      total_rowsXpagina = parseInt((limite_inferior - margin_y + 25) / alto_row)
      // separo el subarray en grupos que contengan la longitud maxima que permite insertar la tabla en 1 hoja
      const subarray_stack = divideArray(subarray, total_rowsXpagina - 1)

      // recorro los grupos de arrays para insertar las tablas en las paginas
      for (let index = 0; index < subarray_stack.length; index++) {
        const stack = subarray_stack[index]
        doc.addPage()
        // isnerta el encabezado
        setEncabezado()
        // inserta la tabla
        doc.table(margin_x, margin_y + 25, stack, headers, { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header })
        // Si se esta insertando la ultima tabla hay que obtener el alto final de la misma para insertar el siguiente elemento a la misma altura
        if (index == subarray_stack.length - 1) {
          y_final_elemento = ((stack.length + 1) * alto_row) + margin_y + 25
        }
      }
    }
  }

  //**  TABLA  *//
  doc.setFontSize(12)
  doc.text('Recepción Operaciones de Caja', width / 2, margin_y + 30, null, null, 'center')
  funcionMaestra(
    margin_y + 35,
    data.detalle.map(detalle => {
      detalle.descripcion = `${detalle.descripcion.substring(0, 45)}${detalle.descripcion.length > 45 ? '...' : ''}`
      detalle.efectivo = format_money(detalle.efectivo)
      detalle.importe = format_money(detalle.importe)
      detalle.otros = format_money(detalle.otros)
      return detalle
    }),
    [
      { id: 'fecha', name: 'fecha', prompt: 'Fecha', width: 25, align: 'left' },
      { id: 'numero', name: 'numero', prompt: 'Numero', width: 20, align: 'left' },
      { id: 'abreviatura', name: 'abreviatura', prompt: 'Abreviatura', width: 35, align: 'left' },
      { id: 'descripcion', name: 'descripcion', prompt: 'Descripción', width: 83, align: 'left' },
      { id: 'efectivo', name: 'efectivo', prompt: 'Efectivo', width: 30, align: 'right' },
      { id: 'otros', name: 'otros', prompt: 'Otros', width: 30, align: 'right' },
      { id: 'importe', name: 'importe', prompt: 'Importe', width: 30, align: 'right' }
    ]
  )
  // totales
  doc.table(margin_x, y_final_elemento, [],
    [
      { id: 'descripcion', name: 'descripcion', prompt: ' ', width: 163, align: 'left' },
      { id: 'efectivo', name: 'efectivo', prompt: format_money(data.total_efectivo), width: 30, align: 'right' },
      { id: 'otros', name: 'otros', prompt: format_money(data.total_otros), width: 30, align: 'right' },
      { id: 'importe', name: 'importe', prompt: format_money(data.total_importe), width: 30, align: 'right' }
    ],
    { autoSize: false, fontSize: 8, padding: 1, headerBackgroundColor: gris_header }
  )
  
  // VERIFICAMOS QUE EL ALTO DEL SIGUIENTE ELEMENTO ENTRE EN LO QUE QUEDA DE PAGINA sino hacemos un salto de pagina
  function verificarSaltoPagina (altura) {
    if (y_final_elemento + altura > limite_inferior) {
      doc.addPage()
      setEncabezado()
      y_final_elemento = margin_y + 25
    }
  }

  // detalle en palabras
  verificarSaltoPagina(30)
  doc.setFontSize(10)
  doc.setFont('Poppins', 'normal')
  const monto = salto_linea(`Recibí Pesos : ${NumerosALetras(data.total_importe)} (${format_money(data.total_importe)}) en Concepto de Cobranzas Varias.`, 100)
  doc.text(monto.parte1, margin_x, y_final_elemento + 15)
  doc.text(monto.parte2, margin_x, y_final_elemento + 20)

  //**  FIRMA  *//
  verificarSaltoPagina(60)
  doc.setLineWidth(0.5)
  doc.line(margin_x + 120, y_final_elemento + 50, width - margin_x - 20, y_final_elemento + 50)
  doc.text('Firma', margin_x + 140, y_final_elemento + 55)

  // agregamos los numeros de pagina al final de cada pagina
  doc.setFontSize(8)
  for (let index = 1; index <= doc.getNumberOfPages(); index++) {
    doc.setPage(index)
    doc.text(`Hoja ${index} de ${doc.getNumberOfPages()}`, margin_x + 3, margin_y + 5)
  }

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')

}

async function facturaTermica (data) {
  const ancho = 80
  const altura = 450

  // instancio el pdf de tamaño large
  let doc = new jsPDF({ format: [ancho, altura] })

  // importo y seteo la feunte
  doc.addFont(require('./fuentes/Poppins-Bold.ttf'), 'Poppins', 'bold')
  doc.addFont(require('./fuentes/Poppins-Regular.ttf'), 'Poppins', 'regular')
  doc.setFont('Poppins', 'bold')
  doc.setFontSize(7)

  const factura = { ...data.encabezado }

  // verifico si la imagen del logo es png o jpg
  const extension = factura.imagen_logo.substring(factura.imagen_logo.lastIndexOf('.'), factura.imagen_logo.size).toLowerCase()
  if (extension == '.png') {
    const logoEmpresa = ArrayBufferToBase64(await get_file(factura.imagen_logo, 'image/png'))
    doc.addImage(logoEmpresa, "PNG", 20, 0, 40, 18, null, null, 'center')
  } else {
    const logoEmpresa = ArrayBufferToBase64(await get_file(factura.imagen_logo, 'image/jpeg'))
    doc.addImage(logoEmpresa, "JPEG", 20, 0, 40, 18, null, null, 'center')
  }

  // nombres de los encabezados
  doc.text('CUIT Nº:', 3, 24)
  doc.text('ING. BRUTOS:', 3, 28)
  doc.text('DOMICILIO:', 3, 32)
  doc.text('INICIO ACTIVIDADES:', 3, 36)
  doc.text('CAT. IVA:', 3, 40)

  if (factura.comprobante_tipo == 'FACTURA') {
    doc.text(`FACTURA "${factura.tipo_factura}"`, 40, 46, null, null, 'center')
  } else {
    doc.text(`NOTA DE CREDITO "${factura.tipo_factura}"`, 40, 46, null, null, 'center')
  }
  doc.text('Nº:', 3, 50)
  doc.text('VENDEDOR:', 3, 54)

  doc.text('CLIENTE:', 3, 60)
  doc.text('CUIT/DNI:', 3, 64)
  doc.text('DOMICILIO:', 3, 68)
  doc.text('IVA:', 3, 72)

  doc.text('CANT./PRECIO UNIT.', 3, 78)
  doc.text('IMPORTE', 72, 78, null, null, 'right')
  doc.text('DESCRIPCION', 3, 81)

  // lineas
  doc.setLineWidth(0.5)
  doc.line(3, 42, 72, 42)
  doc.line(3, 56, 72, 56)
  doc.line(3, 74, 72, 74)

  // datos de los enbabezdos
  doc.setFont('Poppins', 'regular')
  doc.text(factura.nombre_empresa, 3, 20)
  doc.text(factura.cuit_empresa, 14, 24)
  doc.text(factura.ingresos_brutos, 20, 28)
  doc.text(`${factura.sucursal} - ${factura.domicilio_local}`, 18, 32)
  doc.text(factura.inicio_actividades, 30, 36)
  doc.text(factura.cat_iva_empresa, 16, 40)

  doc.text(factura.emynum, 8, 50)
  doc.text(`${factura.fecha} ${factura.hora_solicitud}`, 72, 50, null, null, 'right')
  doc.text(factura.vendedor, 18, 54)

  doc.text(factura.cliente, 14.5, 60)
  doc.text(factura.cuit, 16, 64)
  doc.text(str(factura.cli_domicilio, ''), 18, 68)
  doc.text(factura.condiva, 9, 72)

  /***   DETALLES   ***/

  let y_final = 81
  for (const detalle of data.detalle) {
    // ES COMBO
    if (detalle.combo == 1) {
      // obtengo el precio dependieno del tipo de factura
      let precio = factura.tipo_factura == 'B' ? detalle.importeitem : detalle.neto

      doc.setFont('Poppins', 'regular')
      doc.text(`${detalle.cantidad},000/${precio.toFixed(2).replace('.', ',')}`, 3, y_final + 4)
      doc.text(`$ ${precio.toFixed(2).replace('.', ',')}`, 72, y_final + 4, null, null, 'right')

      doc.setFont('Poppins', 'bold')
      doc.text(detalle.descripcion, 3, y_final + 7)

      doc.setFont('Poppins', 'regular')
      y_final += 7

      for (const combo of data.items_combo) {
        const descripcion = split_varchar(`${combo.detalle}${combo.series ? ` - ${combo.series}` : '' }`, 45)
        for (const linea of descripcion) {
          doc.text(linea, 3, y_final + 3)
          y_final += 3
        }
      }

    }
    // NO ES COMBO
    else {
      // obtengo el precio y el total dependieno del tipo de factura
      let precio = factura.tipo_factura == 'B' ? detalle.itempreciounitario : detalle.neto / detalle.cantidad
      let total = factura.tipo_factura == 'B' ? detalle.importeitem : detalle.neto

      doc.setFont('Poppins', 'regular')
      doc.text(`${detalle.cantidad},000/${precio.toFixed(2).replace('.', ',')}`, 3, y_final + 4)
      doc.text(`$ ${total.toFixed(2).replace('.', ',')}`, 72, y_final + 4, null, null, 'right')

      doc.setFont('Poppins', 'bold')
      y_final += 4

      const descripcion = split_varchar(`${detalle.articulo_codigo} - ${detalle.descripcion}`, 45)
      for (const linea of descripcion) {
        doc.text(linea, 3, y_final + 3)
        y_final += 3
      }
    }
  }

  if (factura.tipo_factura == 'A') {
    doc.setFont('Poppins', 'bold')
    doc.text('Subtotal     Desc.           Neto              IVA 21%             IVA 10,5%', 3, y_final + 4)
    doc.text(`$${factura.neto.toFixed(2).replace('.', ',')}`, 3, y_final + 8)
    doc.text('$0', 20, y_final + 8)
    doc.text(`$${factura.neto.toFixed(2).replace('.', ',')}`, 26, y_final + 8)
    doc.text(`$${factura.iva21.toFixed(2).replace('.', ',')}`, 42, y_final + 8)
    doc.text(`$${factura.iva105.toFixed(2).replace('.', ',')}`, 60, y_final + 8)
    y_final += 8
  }

  // TOTAL
  y_final += 2
  doc.setFont('Poppins', 'bold')
  doc.setFontSize(9)
  doc.text('TOTAL:', 3, y_final + 4)
  doc.text(format_money(factura.total), 72, y_final + 4, null, null, 'right')

  // CAE
  doc.setFont('Poppins', 'regular')
  doc.setFontSize(7)
  doc.text(`CAE: ${factura.cae}`, 3, y_final + 8)
  doc.text(`Vencimiento: ${factura.fecha_venc_cae}`, 3, y_final + 11)

  // QR
  const qr = await QRCode.toDataURL(data.qr)
  doc.addImage(qr, "PNG", 20, y_final + 12, 40, 40, null, null, 'center')
  doc.text('GRACIAS POR SU COMPRA', 40, y_final + 54, null, null, 'center')

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')
  
}

async function facturaA4 (data) {
  // defino las medidas de la hoja
  const width = 210   // ancho de 21 cm
  const height = 297  // alto de 29,7 cm
  const margin_y = 10 // margenes de 1 cm
  const margin_x = 5  // margenes de 0,5 cm

  const limite_inferior = height - margin_y
  const factura = { ...data.encabezado }
  let y_final = 0

  // creo la primer hoja del pdf (por defecto genera una a4)
  let doc = new jsPDF({ putOnlyUsedFonts: false })

  // importo y seteo la feunte
  doc.addFont(require('./fuentes/Poppins-Bold.ttf'), 'Poppins', 'bold')
  doc.addFont(require('./fuentes/Poppins-Medium.ttf'), 'Poppins', 'medium')
  doc.addFont(require('./fuentes/Poppins-Regular.ttf'), 'Poppins', 'regular')

  // obtengo la marca de agua
  let nombreAgua = factura.codigo_empresa == 5 ? 'RADA' : 'RFB'
  if (factura.mayorista == 1) nombreAgua = 'LITECH'
  const marcaAgua = ArrayBufferToBase64(await get_file(`/empresas/agua${nombreAgua}.png`, 'image/png'))

  // funcion para setear el encabezado
  function setEncabezado () {
    // marca de wather
    doc.addImage(marcaAgua, "PNG", 0, 0, width, height)

    // recuadro rectangular
    doc.setLineWidth(0.1)
    doc.setDrawColor('#000000')
    doc.line(margin_x, margin_y, width - margin_x, margin_y)
    doc.line(margin_x, margin_y + 50, width - margin_x, margin_y + 50)
    doc.line(margin_x, margin_y, margin_x, margin_y + 50)
    doc.line(width - margin_x, margin_y, width - margin_x, margin_y + 50)
    // recuadro para la letra
    doc.line(width / 2, margin_y + 25, width / 2, margin_y + 50)
    doc.line((width / 2) - 12.5, margin_y + 25, (width / 2) + 12.5, margin_y + 25)
    doc.line((width / 2) - 12.5, margin_y, (width / 2) - 12.5, margin_y + 25)
    doc.line((width / 2) + 12.5, margin_y, (width / 2) + 12.5, margin_y + 25)

    // letra
    doc.setFontSize(48)
    doc.setFont('Poppins', 'bold')
    doc.text(factura.tipo_factura, width / 2, margin_y + 18, null, null, 'center')

    // info
    doc.setFontSize(12)
    doc.setFont('Poppins', 'medium')
    doc.text(factura.nombre_empresa, margin_x + 3, margin_y + 27)
    doc.setFontSize(10)
    doc.text(`Cat. Iva: ${factura.cat_iva_empresa}`, margin_x + 3, margin_y + 32)
    doc.text(`Inicio de Actividades: ${factura.inicio_actividades}`, margin_x + 3, margin_y + 37)
    if (factura.mayorista != 1) doc.text(`Domicilio: ${factura.sucursal} - ${factura.domicilio_local}`, margin_x + 3, margin_y + 42)
    doc.text(factura.vendedor ? `Vendedor: ${factura.vendedor}` : '', margin_x + 3, margin_y + (factura.mayorista == 1 ? 42 : 47))

    doc.setFontSize(20)
    doc.setFont('Poppins', 'bold')
    doc.text(factura.comprobante_tipo, margin_x + 130, margin_y + 15)
    doc.setFontSize(12)
    doc.setFont('Poppins', 'medium')
    doc.text(`Nº ${factura.emynum}`, margin_x + 130, margin_y + 21)
    doc.setFontSize(10)
    doc.text(`Fecha: ${factura.fecha}`, margin_x + 130, margin_y + 27)
    doc.text(`CUIT: ${factura.cuit_empresa}`, margin_x + 130, margin_y + 32)
    doc.text(`Ing. Brutos: ${factura.ingresos_brutos}`, margin_x + 130, margin_y + 37)

    // datos del cliente
    doc.text(`Cliente: ${factura.cliente}`, margin_x, margin_y + 57)
    doc.text(`Domicilio: ${factura.cli_domicilio}`, margin_x, margin_y + 62)
    doc.text(`Iva: ${factura.condiva}`, margin_x, margin_y + 67)
    doc.text(`CUIT: ${factura.cuit}`, width - (margin_x * 2), margin_y + 57, null, null, 'right')
    doc.text('Remito: -', width - (margin_x * 2), margin_y + 62, null, null, 'right')
    
    // encabezado del detalle
    doc.line(margin_x, margin_y + 70, width - margin_x, margin_y + 70)
    doc.setFont('Poppins', 'bold')
    doc.text('Cant.', margin_x + 1, margin_y + 75)
    doc.text('Código', margin_x + 20, margin_y + 75)
    doc.text('Descripción', margin_x + 40, margin_y + 75)
    doc.text('Precio Unit.', margin_x + 150, margin_y + 75)
    doc.text('Importe', width - margin_x - 1, margin_y + 75, null, null, 'right')

    y_final = margin_y + 78
  }
  setEncabezado()

  function saltoPagina (alto) {
    if (y_final + alto > limite_inferior) {
      doc.addPage()
      setEncabezado()
      y_final = margin_y + 78
    }
  }

  for (const detalle of data.detalle) {
    // controlo si hace falta un salto de pagina
    saltoPagina(15)

    doc.setDrawColor('#757575')
    doc.line(margin_x, y_final, width - margin_x, y_final)

    doc.setFontSize(8)
    doc.setFont('Poppins', 'regular')
    doc.text(`${detalle.cantidad}`, margin_x + 5, y_final + 4.5)
    doc.text(`${detalle.articulo_codigo ? detalle.articulo_codigo : ''}`, margin_x + 20, y_final + 4.5)

    let precio = detalle.combo == 1 ? detalle.importeitem : detalle.itempreciounitario
    doc.text(`$ ${precio.toFixed(2).replace('.', ',')}`, width - margin_x - 32, y_final + 4.5, null, null, 'right')
    doc.text(`$ ${detalle.importeitem.toFixed(2).replace('.', ',')}`, width - margin_x - 1, y_final + 4.5, null, null, 'right')
    
    if (detalle.combo == 1) {
      doc.text(detalle.descripcion, margin_x + 40, y_final + 4.5)
      y_final += 5.5
      for (const combo of data.items_combo) {
        const descripcion = split_varchar(`${combo.detalle}${combo.series ? `${combo.series}` : '' }`, 70)
        for (const linea of descripcion) {
          saltoPagina(5)
          doc.text(linea, margin_x + 40, y_final + 4)
          y_final += 4
        }

        y_final += 0.5
      }
    } else {
      const descripcion = split_varchar(detalle.descripcion, 70)
      for (const linea of descripcion) {
        saltoPagina(5)
        doc.text(linea, margin_x + 40, y_final + 4)
        y_final += 4
      }

      y_final += 2.5
    }
  }

  // verifico si el footer entra en lo que quedo de pagina
  saltoPagina(factura.tipo_factura == 'A' ? 85 : 55)
  
  // si es factura A agrego los totales
  if (factura.tipo_factura == 'A') {
    doc.setFontSize(10)
    doc.setDrawColor('#000000')
    doc.line(margin_x, margin_y + 216, width - margin_x, margin_y + 216)
    doc.setFont('Poppins', 'bold')
    doc.text('Subtotal', margin_x + 1, margin_y + 221)
    doc.text('Descuento', margin_x + 35, margin_y + 221)
    doc.text('Neto', margin_x + 70, margin_y + 221)
    doc.text('IVA 21%', margin_x + 110, margin_y + 221)
    doc.text('IVA 10,5%', margin_x + 150, margin_y + 221)
    doc.text('Total', width - margin_x - 1, margin_y + 221, null, null, 'right')

    doc.setDrawColor('#757575')
    doc.line(margin_x, margin_y + 224, width - margin_x, margin_y + 224)
    doc.setFont('Poppins', 'bold')
    doc.text(`$${factura.neto.toFixed(2).replace('.', ',')}`, margin_x + 1, margin_y + 229)

    doc.text('$0', margin_x + 35, margin_y + 229)
    doc.text(`$${factura.neto.toFixed(2).replace('.', ',')}`, margin_x + 70, margin_y + 229)
    
    doc.text(`$${factura.iva21.toFixed(2).replace('.', ',')}`, margin_x + 110, margin_y + 229)
    
    doc.text(`$${factura.iva105.toFixed(2).replace('.', ',')}`, margin_x + 150, margin_y + 229)
    
    doc.text(`$${factura.total.toFixed(2).replace('.', ',')}`, width - margin_x - 1, margin_y + 229, null, null, 'right')
  }

  // total en letras
  doc.setFontSize(12)
  doc.setFont('Poppins', 'medium')
  doc.text(`Son: ${NumerosALetras(factura.total)}`, margin_x + 1, 248)

  // QR e imagen afip
  const qr = await QRCode.toDataURL(data.qr)
  const afipImg = ArrayBufferToBase64(await get_file('/empresas/afiplogo.png', 'image/png'))
  doc.addImage(qr, "PNG", margin_x, 250, 40, 40, null, null, 'center')
  doc.addImage(afipImg, "PNG", margin_x + 40, 264, 119, 35)

  // total y cae
  doc.setFontSize(18)
  doc.setFont('Poppins', 'bold')
  doc.text(`Total: ${format_money(factura.total)}`, 135, 260)
  doc.setFontSize(12)
  doc.setFont('Poppins', 'medium')
  doc.text('GRACIAS POR SU COMPRA', 135, 267)
  doc.text(`CAE: ${factura.cae}`, 135, 272)
  doc.text(`Vencimiento: ${factura.fecha_venc_cae}`, 135, 277)

  // solo si tiene + de 1 hoja agrego la numeracion
  if (doc.getNumberOfPages() > 1) {
    doc.setFontSize(10)
    for (let index = 1; index <= doc.getNumberOfPages(); index++) {
      doc.setPage(index)
      doc.text(`Hoja ${index} de ${doc.getNumberOfPages()}`, margin_x + 130, margin_y + 45)
    }
  }

  // devuelvo los bytes del pdf generado
  return doc.output('arraybuffer')
}

export {
  pdfCobroOnline,
  operacionesCajaTermica,
  operacionesCajaA4,
  operacionesAceptacionA4,
  facturaTermica,
  facturaA4,
  gastosCajaA4
}